/* Variables */
/* Styles */
body {
  font-family: 'Anuphan', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #000;
}

strong {
  font-weight: 500;
}

.Page {
  background-image: url("/img/bg.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: #040205;
}

h1 {
  margin: 0;
  font-family: 'Noticia Text', serif;
  font-size: 30px;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
}

h2 {
  margin: 5px 0 0;
  font-family: 'Noticia Text', serif;
  font-size: 18px;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
  color: #C221F8;
}

h3 {
  margin: 0;
  font-family: 'Noticia Text', serif;
  font-size: 30px;
  font-weight: 300;
  text-align: center;
}

h4 {
  margin: 5px 0 0;
  font-family: 'Noticia Text', serif;
  font-size: 24px;
  font-weight: 300;
  font-style: italic;
  text-align: center;
  color: #330475;
}

.underline {
  width: 75px;
  height: 2px;
  margin: 25px auto 0;
  background-color: #18C3CA;
}

.swal-bio {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.swal-bio h2 {
  color: #000;
}

.swal-bio p {
  margin-top: 25px;
  font-size: 16px;
  color: #000;
  text-align: left;
  line-height: 34px;
}

.swal-bio p:last-child {
  margin-bottom: 25px;
}

.alert-success {
  background-color: #C221F8;
  color: #fff;
  border-color: #ac1ddc;
}

/* Layout */
.wrapper {
  padding: 75px 0;
}

/* Header */
.Header {
  padding-top: 40px;
}

.Header #logo img {
  width: 100%;
}

.Header .Header__top-navigation {
  margin-top: 75px;
  background: #330475;
}

.Header .Header__top-navigation ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.Header .Header__top-navigation ul li {
  border-bottom: 1px solid #040205;
}

.Header .Header__top-navigation ul li:last-child {
  border-bottom: none;
}

.Header .Header__top-navigation ul li:last-child a {
  margin-bottom: 0;
  border-bottom: none;
}

.Header .Header__top-navigation ul li.active a {
  color: #ffff93;
}

.Header .Header__top-navigation ul li.free-sessions {
  background: #18C3CA;
}

.Header .Header__top-navigation ul li a {
  width: 100%;
  height: 50px;
  display: block;
  margin-bottom: 1px;
  padding-top: 8px;
  color: #fff;
  text-align: center;
}

.Header .Header__top-navigation ul li a:hover, .Header .Header__top-navigation ul li a:focus {
  text-decoration: none;
}

.Header .Header__top-navigation ul li a:hover {
  color: #ffff93;
}

.Header .Header__top-navigation ul.nav-trigger li {
  border-bottom: none;
}

.Header .Header__top-navigation ul.nav-trigger li a {
  padding-top: 10px;
  font-size: 18px;
}

.Header .Header__top-navigation ul.nav-items {
  display: none;
}

.Header .Header__top-navigation ul.nav-items li a {
  padding-top: 10px;
  font-size: 18px;
}

@media screen and (min-width: 768px) {
  .Header .Header__top-navigation {
    height: 50px;
  }
  .Header .Header__top-navigation ul.nav-trigger {
    display: none;
  }
  .Header .Header__top-navigation ul.nav-items {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    list-style: none;
  }
  .Header .Header__top-navigation ul.nav-items li {
    display: flex;
    flex-grow: 1;
    align-items: flex-start;
    border-left: 1px solid #040205;
    border-bottom: none;
  }
  .Header .Header__top-navigation ul.nav-items li:first-child {
    border-left: none;
  }
  .Header .Header__top-navigation ul.nav-items li:last-child {
    border-right: none;
  }
  .Header .Header__top-navigation ul.nav-items li.active a {
    color: #ffff93;
  }
  .Header .Header__top-navigation ul.nav-items li a {
    width: 100%;
    height: 49px;
    display: block;
    padding-top: 12px;
    font-family: 'Anuphan', sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: #fff;
    text-align: center;
    border-bottom: none;
  }
  .Header .Header__top-navigation ul.nav-items li a:hover, .Header .Header__top-navigation ul.nav-items li a:focus {
    text-decoration: none;
  }
  .Header .Header__top-navigation ul.nav-items li a:hover {
    color: #ffff93;
  }
}

@media screen and (min-width: 480px) {
  .Header #logo {
    max-width: 500px;
    display: block;
    margin: -20px auto 0;
  }
}

/* Banner */
.Banner {
  height: 360px;
}

.Banner.Banner__welcome {
  background: url("/img/banner/welcome.jpg?v=2") no-repeat center;
  background-size: cover;
}

.Banner.Banner__about-us {
  background: url("/img/banner/about-us.jpg") no-repeat center;
  background-size: cover;
}

.Banner.Banner__free-sessions {
  background: url("/img/banner/free-sessions.jpg") no-repeat center;
  background-size: cover;
}

.Banner.Banner__love-made-easy {
  background: url("/img/banner/love-made-easy.jpg") no-repeat center;
  background-size: cover;
}

.Banner.Banner__teen-mentorship {
  background: url("/img/banner/teen-mentorship.jpg") no-repeat center;
  background-size: cover;
}

.Banner.Banner__executives {
  background: url("/img/banner/executives.jpg") no-repeat center;
  background-size: cover;
}

.Banner.Banner__social-master {
  background: url("/img/banner/social-master.jpg?v=3") no-repeat center right;
  background-size: cover;
}

.Banner.Banner__stress-free-and-happy {
  background: url("/img/banner/stress-free-and-happy.jpg?v=3") no-repeat center;
  background-size: cover;
}

.Banner.Banner__student-success {
  background: url("/img/banner/student-success.jpg") no-repeat center;
  background-size: cover;
}

.Banner.Banner__crg {
  background: url("/img/banner/crg.jpg") no-repeat center;
  background-size: cover;
}

.Banner.Banner__crg-change {
  background: url("/img/banner/crg-change.jpg") no-repeat center;
  background-size: cover;
}

.Banner.Banner__crg-renewal {
  background: url("/img/banner/crg-renewal.jpg") no-repeat center;
  background-size: cover;
}

.Banner.Banner__crg-growth {
  background: url("/img/banner/crg-growth.jpg") no-repeat center;
  background-size: cover;
}

.Banner.Banner__1-1-coaching {
  background: url("/img/banner/1-1-coaching.jpg") no-repeat center;
  background-size: cover;
}

.Banner.Banner__contact-us {
  background: url("/img/banner/contact-us.jpg") no-repeat center;
  background-size: cover;
}

.Banner.Banner__lifeward-choices {
  background: url("/img/banner/contact-us.jpg") no-repeat center;
  background-size: cover;
}

.Banner .slogan {
  width: 275px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  padding: 7px 0;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  background: #18C3CA;
}

/* Content */
.Page .Content {
  background-color: #fff;
}

.Page .Content .Content__title {
  padding: 50px;
}

.Page .mindset-makeover .banner {
  height: 50px;
  position: fixed;
  z-index: 100;
  padding: 12px 20px;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  -webkit-transform-origin: 0 50%;
  -moz-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  -o-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transform: rotate(-90deg) translate(-50%, 50%);
  -moz-transform: rotate(-90deg) translate(-50%, 50%);
  -ms-transform: rotate(-90deg) translate(-50%, 50%);
  -o-transform: rotate(-90deg) translate(-50%, 50%);
  transform: rotate(-90deg) translate(-50%, 50%);
  font-size: 16px;
  color: #fff;
  background-color: #18C3CA;
  text-align: center;
}

.Page .mindset-makeover .banner .highlight {
  font-size: 18px;
  font-weight: 600;
  color: #c80000;
}

.Page .mindset-makeover #mindset-makeover {
  text-align: center;
}

.Page .mindset-makeover #mindset-makeover h3 {
  font-size: 22px;
}

.Page .mindset-makeover #mindset-makeover p {
  margin-top: 25px;
  font-size: 16px;
}

.Page .mindset-makeover #mindset-makeover .learn-more {
  display: inline-block;
  padding: 9px 11px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  background-color: #18C3CA;
}

.Page .mindset-makeover #mindset-makeover .learn-more:hover, .Page .mindset-makeover #mindset-makeover .learn-more:focus {
  text-decoration: none;
}

/* Content: Welcome */
.Page__welcome .Content .Content__crg {
  padding: 50px 15px;
  text-align: center;
}

.Page__welcome .Content .Content__crg .crg {
  margin: 50px 0;
}

.Page__welcome .Content .Content__crg p {
  margin: 50px 0 15px;
  font-size: 16px;
  color: #000;
  line-height: 34px;
}

.Page__welcome .Content .Content__crg .learn-more {
  display: inline-block;
  padding: 9px 11px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  background-color: #18C3CA;
}

.Page__welcome .Content .Content__crg .learn-more:hover, .Page__welcome .Content .Content__crg .learn-more:focus {
  text-decoration: none;
}

.Page__welcome .Content .Content__title {
  background-color: #C221F8;
}

.Page__welcome .Content .Content__title h1, .Page__welcome .Content .Content__title h2 {
  color: #fff;
}

.Page__welcome .Content .Content__title .underline {
  background-color: #fff;
}

.Page__welcome .Content .Content__most-popular {
  padding: 0 50px 50px;
  background-color: #C221F8;
}

.Page__welcome .Content .Content__most-popular .programs-list:first-child div:first-child .program {
  margin-top: 0;
}

.Page__welcome .Content .Content__most-popular .programs-list .program {
  max-width: 415px;
  height: 200px;
  margin: 50px auto 0;
  position: relative;
  display: block;
  border: 3px solid #fff;
}

.Page__welcome .Content .Content__most-popular .programs-list .program.love-made-easy {
  background: url("/img/programs/bg-love-made-easy.jpg") no-repeat center;
  background-size: cover;
}

.Page__welcome .Content .Content__most-popular .programs-list .program.teen-mentorship {
  background: url("/img/programs/bg-teen-mentorship.jpg") no-repeat center;
  background-size: cover;
}

.Page__welcome .Content .Content__most-popular .programs-list .program.executives {
  background: url("/img/programs/bg-executives.jpg") no-repeat center;
  background-size: cover;
}

.Page__welcome .Content .Content__most-popular .programs-list .program.social-master {
  background: url("/img/programs/bg-social-master.jpg?v=3") no-repeat center;
  background-size: cover;
}

.Page__welcome .Content .Content__most-popular .programs-list .program.stress-free-and-happy {
  background: url("/img/programs/bg-stress-free-and-happy.jpg?v=2") no-repeat center;
  background-size: cover;
}

.Page__welcome .Content .Content__most-popular .programs-list .program.student-success {
  background: url("/img/programs/bg-student-success.jpg") no-repeat center;
  background-size: cover;
}

.Page__welcome .Content .Content__most-popular .programs-list .program .title {
  max-width: 200px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  padding: 5px 0 2px;
  font-size: 16px;
  font-weight: 500;
  color: #330475;
  text-transform: uppercase;
  text-align: center;
  background: #fff;
}

@media screen and (min-width: 992px) {
  .Page__welcome .Content .Content__most-popular .programs-list:first-child div:last-child .program {
    margin-top: 0;
  }
}

@media screen and (min-width: 480px) {
  .Page__welcome .Content .Content__crg {
    padding: 50px;
  }
  .Page__welcome .Content .Content__welcome .sarah-aguinaldo {
    width: 290px;
    height: 200px;
  }
}

/* Content: About Us */
.Page__about-us .Content .Content__about-us {
  padding: 50px 15px;
  font-size: 16px;
  color: #000;
  line-height: 34px;
  background-color: #C221F8;
}

.Page__about-us .Content .Content__about-us p {
  margin: 25px 0;
}

.Page__about-us .Content .Content__about-us p:first-child {
  margin-top: 0;
}

.Page__about-us .Content .Content__about-us p:last-child {
  margin-bottom: 0;
}

.Page__about-us .Content .Content__about-us p .highlight {
  font-weight: 500;
  font-style: italic;
}

.Page__about-us .Content .Content__about-us ul li {
  color: #fff;
}

.Page__about-us .Content .Content__about-us ul li a {
  color: #fff;
  text-decoration: none;
  font-weight: 600;
}

.Page__about-us .Content .Content__about-us ul li a:hover {
  border-bottom: 1px solid #fff;
}

@media screen and (min-width: 480px) {
  .Page__about-us .Content .Content__about-us {
    padding: 50px;
  }
}

/* Content: Free Sessions */
.Page__free-sessions .Content .Content__title {
  text-align: center;
}

.Page__free-sessions .Content .Content__title h2 {
  margin-top: 25px;
}

.Page__free-sessions .Content .Content__title h2 .highlight {
  color: #0b0bff;
}

.Page__free-sessions .Content .Content__title h2 a {
  color: #C221F8;
  text-decoration: none;
}

.Page__free-sessions .Content .Content__title h2 a:hover {
  border-bottom: 1px solid #0b0bff;
}

.Page__free-sessions .Content .Content__title .underline {
  margin: 25px auto;
}

.Page__free-sessions .Content .Content__free-sessions {
  padding: 50px 15px;
  font-size: 16px;
  color: #000;
  line-height: 34px;
  background-color: #f5f5f5;
}

.Page__free-sessions .Content .Content__free-sessions .jump-girl {
  text-align: center;
}

.Page__free-sessions .Content .Content__free-sessions .jump-girl img {
  width: 100%;
  max-width: 350px;
}

.Page__free-sessions .Content .Content__free-sessions h2 {
  margin: 50px auto 30px;
  font-size: 24px;
}

.Page__free-sessions .Content .Content__free-sessions h2 .highlight {
  color: #0b0bff;
}

.Page__free-sessions .Content .Content__free-sessions ul {
  margin-left: 50px;
  font-size: 18px;
}

.Page__free-sessions .Content .Content__free-sessions p {
  margin: 25px 0;
}

.Page__free-sessions .Content .Content__free-sessions p:first-child {
  margin-top: 0;
}

.Page__free-sessions .Content .Content__free-sessions p:last-child {
  margin-bottom: 0;
}

.Page__free-sessions .Content .Content__free-sessions p .highlight {
  font-weight: 500;
  font-style: italic;
}

.Page__free-sessions .Content .Content__free-sessions .form {
  margin-top: 50px;
  text-align: center;
}

.Page__free-sessions .Content .Content__free-sessions .form p {
  margin-bottom: 0;
  font-size: 18px;
}

.Page__free-sessions .Content .Content__free-sessions .form p:first-child {
  color: #337ab7;
}

.Page__free-sessions .Content .Content__free-sessions .form p .highlight {
  font-style: normal;
}

.Page__free-sessions .Content .Content__free-sessions .form > ul {
  color: #337ab7;
  text-align: left;
}

.Page__free-sessions .Content .Content__free-sessions .form .form-free-session {
  margin-top: 50px;
  text-align: left;
}

.Page__free-sessions .Content .Content__free-sessions .form .form-free-session ul {
  margin-left: 25px;
  font-size: 16px;
}

.Page__free-sessions .Content .Content__free-sessions .form .form-free-session label {
  font-weight: 500;
}

.Page__free-sessions .Content .Content__free-sessions .form .form-free-session input[type="text"],
.Page__free-sessions .Content .Content__free-sessions .form .form-free-session input[type="email"],
.Page__free-sessions .Content .Content__free-sessions .form .form-free-session input[type="tel"] {
  height: auto;
  padding: 12px 16px;
  font-size: 16px;
  border-radius: 0;
}

.Page__free-sessions .Content .Content__free-sessions .form .form-free-session button {
  font-size: 16px;
  border-radius: 0;
}

.Page__free-sessions .Content .Content__free-sessions .form .form-free-session .btn-submit {
  color: #fff;
  background-color: #C221F8;
  border-color: #ac1ddc;
}

@media screen and (min-width: 480px) {
  .Page__free-sessions .Content .Content__free-sessions {
    padding: 50px;
  }
}

/* Content: 1:1 Coaching */
.Page__1-1-coaching .Content .Content__title {
  text-align: center;
}

.Page__1-1-coaching .Content .Content__title h2 {
  margin-top: 25px;
  font-size: 24px;
}

.Page__1-1-coaching .Content .Content__title h2 .highlight {
  color: #0b0bff;
}

.Page__1-1-coaching .Content .Content__title h2 a {
  color: #C221F8;
  text-decoration: none;
}

.Page__1-1-coaching .Content .Content__title h2 a:hover {
  border-bottom: 1px solid #0b0bff;
}

.Page__1-1-coaching .Content .Content__title .underline {
  margin: 25px auto;
}

.Page__1-1-coaching .Content .Content__1-1-coaching {
  padding: 50px 15px;
  font-size: 16px;
  color: #000;
  line-height: 34px;
  background-color: #f5f5f5;
}

.Page__1-1-coaching .Content .Content__1-1-coaching h2 {
  margin: 50px auto 30px;
  font-size: 24px;
}

.Page__1-1-coaching .Content .Content__1-1-coaching h2 .highlight {
  color: #0b0bff;
}

.Page__1-1-coaching .Content .Content__1-1-coaching p {
  margin: 25px 0;
  font-size: 18px;
  text-align: center;
}

.Page__1-1-coaching .Content .Content__1-1-coaching p:first-child {
  margin-top: 0;
}

.Page__1-1-coaching .Content .Content__1-1-coaching p:last-child {
  margin-bottom: 0;
}

.Page__1-1-coaching .Content .Content__1-1-coaching p .highlight {
  font-weight: 500;
  font-style: italic;
}

.Page__1-1-coaching .Content .Content__1-1-coaching .form {
  margin-top: 50px;
  text-align: center;
}

.Page__1-1-coaching .Content .Content__1-1-coaching .form p {
  margin-bottom: 0;
  font-size: 18px;
}

.Page__1-1-coaching .Content .Content__1-1-coaching .form p:first-child {
  color: #337ab7;
}

.Page__1-1-coaching .Content .Content__1-1-coaching .form p .highlight {
  font-style: normal;
}

.Page__1-1-coaching .Content .Content__1-1-coaching .form > ul {
  color: #337ab7;
  text-align: left;
}

.Page__1-1-coaching .Content .Content__1-1-coaching .form .form-free-session {
  margin-top: 50px;
  text-align: left;
}

.Page__1-1-coaching .Content .Content__1-1-coaching .form .form-free-session ul {
  margin-left: 25px;
  font-size: 16px;
}

.Page__1-1-coaching .Content .Content__1-1-coaching .form .form-free-session label {
  font-weight: 500;
}

.Page__1-1-coaching .Content .Content__1-1-coaching .form .form-free-session input[type="text"],
.Page__1-1-coaching .Content .Content__1-1-coaching .form .form-free-session input[type="email"],
.Page__1-1-coaching .Content .Content__1-1-coaching .form .form-free-session input[type="tel"] {
  height: auto;
  padding: 12px 16px;
  font-size: 16px;
  border-radius: 0;
}

.Page__1-1-coaching .Content .Content__1-1-coaching .form .form-free-session button {
  font-size: 16px;
  border-radius: 0;
}

.Page__1-1-coaching .Content .Content__1-1-coaching .form .form-free-session .btn-submit {
  color: #fff;
  background-color: #C221F8;
  border-color: #ac1ddc;
}

@media screen and (min-width: 480px) {
  .Page__1-1-coaching .Content .Content__free-sessions {
    padding: 50px;
  }
}

/* Content: Sign Waiver */
.Page__sign-waiver .mindset-makeover {
  display: none;
}

.Page__sign-waiver .Content .Content__title {
  text-align: center;
}

.Page__sign-waiver .Content .Content__title h2 {
  margin-top: 25px;
}

.Page__sign-waiver .Content .Content__title h2 .highlight {
  color: #0b0bff;
}

.Page__sign-waiver .Content .Content__title h2 a {
  color: #C221F8;
  text-decoration: none;
}

.Page__sign-waiver .Content .Content__title h2 a:hover {
  border-bottom: 1px solid #0b0bff;
}

.Page__sign-waiver .Content .Content__title .underline {
  margin: 25px auto;
}

.Page__sign-waiver .Content .Content__sign-waiver {
  padding: 50px 15px;
  font-size: 16px;
  color: #000;
  line-height: 34px;
  background-color: #f5f5f5;
}

.Page__sign-waiver .Content .Content__sign-waiver .logo {
  margin-bottom: 25px;
  text-align: center;
}

.Page__sign-waiver .Content .Content__sign-waiver p {
  margin: 25px 0;
}

.Page__sign-waiver .Content .Content__sign-waiver p:first-child {
  margin-top: 0;
}

.Page__sign-waiver .Content .Content__sign-waiver p:last-child {
  margin-bottom: 0;
}

.Page__sign-waiver .Content .Content__sign-waiver p .highlight {
  font-weight: 500;
  font-style: italic;
}

.Page__sign-waiver .Content .Content__sign-waiver .form {
  margin-top: 50px;
  text-align: center;
}

.Page__sign-waiver .Content .Content__sign-waiver .form p {
  margin-bottom: 0;
  font-size: 18px;
}

.Page__sign-waiver .Content .Content__sign-waiver .form p:first-child {
  color: #337ab7;
}

.Page__sign-waiver .Content .Content__sign-waiver .form p .highlight {
  font-style: normal;
}

.Page__sign-waiver .Content .Content__sign-waiver .form > ul {
  color: #337ab7;
  text-align: left;
}

.Page__sign-waiver .Content .Content__sign-waiver .form .form-sign-waiver {
  text-align: left;
}

.Page__sign-waiver .Content .Content__sign-waiver .form .form-sign-waiver ul {
  margin-left: 25px;
  font-size: 16px;
}

.Page__sign-waiver .Content .Content__sign-waiver .form .form-sign-waiver label {
  font-weight: 500;
}

.Page__sign-waiver .Content .Content__sign-waiver .form .form-sign-waiver input[type="text"],
.Page__sign-waiver .Content .Content__sign-waiver .form .form-sign-waiver input[type="email"],
.Page__sign-waiver .Content .Content__sign-waiver .form .form-sign-waiver input[type="tel"] {
  height: auto;
  padding: 12px 16px;
  font-size: 16px;
  border-radius: 0;
}

.Page__sign-waiver .Content .Content__sign-waiver .form .form-sign-waiver .form-check label {
  margin-left: 10px;
  display: inline;
}

.Page__sign-waiver .Content .Content__sign-waiver .form .form-sign-waiver button {
  font-size: 16px;
  border-radius: 0;
}

.Page__sign-waiver .Content .Content__sign-waiver .form .form-sign-waiver .btn-submit {
  color: #fff;
  background-color: #C221F8;
  border-color: #ac1ddc;
}

@media screen and (min-width: 480px) {
  .Page__sign-waiver .Content .Content__sign-waiver {
    padding: 50px;
  }
}

@media screen and (min-width: 768px) {
  .Page__sign-waiver .mindset-makeover {
    display: block;
  }
}

/* Content: CRG */
[class*="Page__crg"] .Content .Content__crg {
  padding: 50px 15px;
  font-size: 16px;
  text-align: center;
  background-color: #f5f5f5;
}

[class*="Page__crg"] .Content .Content__crg h2 {
  font-size: 24px;
}

[class*="Page__crg"] .Content .Content__crg p {
  margin: 25px 0;
  color: #000;
  line-height: 34px;
  text-align: left;
}

[class*="Page__crg"] .Content .Content__crg p:first-child {
  margin-top: 0;
}

[class*="Page__crg"] .Content .Content__crg p:last-child {
  margin-bottom: 0;
}

[class*="Page__crg"] .Content .Content__crg p .highlight {
  font-weight: 500;
  font-style: italic;
}

[class*="Page__crg"] .Content .Content__crg ul {
  margin-bottom: 0;
  text-align: left;
}

[class*="Page__crg"] .Content .Content__crg .phase {
  max-width: 200px;
  position: relative;
  display: block;
  margin: 0 auto;
  padding: 10px 15px;
  background-color: #C221F8;
  text-decoration: none;
}

[class*="Page__crg"] .Content .Content__crg .phase:after {
  content: '';
  position: absolute;
  top: 50%;
  right: -30px;
  margin-top: -15px;
  z-index: 1;
  border: solid 15px transparent;
  border-left-color: #C221F8;
}

[class*="Page__crg"] .Content .Content__crg .phase p {
  font-size: 24px;
  color: #fff;
  text-transform: uppercase;
  line-height: 28px;
}

[class*="Page__crg"] .Content .Content__crg .phase p .subtitle {
  display: block;
  font-size: 18px;
  font-style: italic;
  color: #fff;
}

[class*="Page__crg"] .Content .Content__crg .intro p {
  font-weight: 500;
  text-align: center;
}

@media screen and (max-width: 992px) {
  [class*="Page__crg"] .Content .Content__crg .phase {
    margin-top: 15px;
  }
}

@media screen and (min-width: 480px) {
  [class*="Page__crg"] .Content .Content__crg {
    padding: 50px;
  }
}

/* Content: Most Popular Programs */
.Page .Content .Content__program {
  padding: 50px 15px;
  background-color: #C221F8;
  font-size: 16px;
  color: #000;
  line-height: 34px;
}

.Page .Content .Content__program p {
  margin: 25px 0;
  color: #fff;
}

.Page .Content .Content__program p:first-child {
  margin-top: 0;
}

.Page .Content .Content__program p:last-child {
  margin-bottom: 0;
}

.Page .Content .Content__program p .highlight {
  font-weight: 500;
  font-style: italic;
}

.Page .Content .Content__program p a {
  color: #fff;
  text-decoration: none;
  font-weight: 600;
}

.Page .Content .Content__program p a:hover {
  border-bottom: 1px solid #fff;
}

.Page .Content .Content__program ul {
  margin-bottom: 0;
}

.Page .Content .Content__program ul li {
  color: #fff;
}

.Page .Content .Content__program ul li a {
  color: #fff;
  text-decoration: none;
  font-weight: 600;
}

.Page .Content .Content__program ul li a:hover {
  border-bottom: 1px solid #fff;
}

@media screen and (min-width: 480px) {
  .Page .Content .Content__program {
    padding: 50px;
  }
}

/* Content: Contact Us */
.Content .Content__title {
  padding: 50px;
}

.Content .Content__contact-us {
  padding: 50px 15px;
  background-color: #C221F8;
}

.Content .Content__contact-us .info:first-child h2 {
  margin-top: 0;
}

.Content .Content__contact-us .info h2 {
  margin-top: 50px;
  color: #fff;
  text-align: left;
}

.Content .Content__contact-us .info p {
  margin-top: 5px;
  font-size: 24px;
  color: #fff;
  word-break: break-word;
}

.Content .Content__contact-us .info p a {
  color: #fff;
  text-decoration: none;
}

.Content .Content__contact-us .info p a:hover {
  border-bottom: 1px solid #fff;
}

.Content .Content__contact-us p {
  margin: 25px 0;
  font-size: 16px;
  color: #fff;
  line-height: 34px;
}

.Content .Content__contact-us p:first-child {
  margin-top: 0;
}

.Content .Content__contact-us p:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 480px) {
  .Content .Content__contact-us {
    padding: 50px;
  }
}

/* Content: Founder */
.Page__founder .Content .Content__founder {
  padding: 50px 15px;
  background-color: #f5f5f5;
  font-size: 16px;
  color: #000;
  line-height: 34px;
  text-align: center;
}

.Page__founder .Content .Content__founder p {
  margin: 25px 0;
  text-align: left;
}

.Page__founder .Content .Content__founder p:last-child {
  margin-bottom: 0;
}

.Page__founder .Content .Content__founder p.free-gifts {
  text-align: center;
}

.Page__founder .Content .Content__founder p.free-gifts a {
  color: red;
  font-weight: bold;
}

.Page__founder .Content .Content__founder p .highlight {
  font-weight: 500;
  font-style: italic;
}

.Page__founder .Content .Content__founder .bio p {
  text-indent: 40px;
}

.Page__founder .Content .Content__founder #jim-and-jim {
  text-align: left;
}

.Page__founder .Content .Content__founder #jim-and-jim .modal-content {
  border-radius: 0;
}

.Page__founder .Content .Content__founder #jim-and-jim .modal-content img {
  width: 100%;
}

.Page__founder .Content .Content__founder #jim-and-jim .modal-content .jim-britt, .Page__founder .Content .Content__founder #jim-and-jim .modal-content .jim-lutes {
  font-size: 16px;
  line-height: 26px;
}

.Page__founder .Content .Content__founder #jim-and-jim .modal-content .jim-lutes {
  margin-top: 50px;
}

.Page__founder .Content .Content__founder #jim-and-jim .modal-content .btn {
  border-radius: 0;
}

.Page__founder .Content .Content__founder #sarah-message .modal-content {
  border-radius: 0;
}

.Page__founder .Content .Content__founder #sarah-message .modal-content .btn {
  border-radius: 0;
}

.Page__founder .Content .Content__founder #sarah-message .modal-content .highlight {
  font-style: normal;
  color: #C221F8;
}

@media screen and (min-width: 480px) {
  .Page__founder .Content .Content__founder {
    padding: 50px;
  }
}

/* Content: Dedications, Testimonials */
.Page .Content .Content__dedications, .Page .Content .Content__testimonials {
  padding: 50px 15px;
  background-color: #f5f5f5;
  font-size: 16px;
  color: #000;
  line-height: 34px;
}

.Page .Content .Content__dedications h2, .Page .Content .Content__testimonials h2 {
  font-size: 24px;
}

.Page .Content .Content__dedications p, .Page .Content .Content__testimonials p {
  margin: 25px 0;
}

.Page .Content .Content__dedications p:first-child, .Page .Content .Content__testimonials p:first-child {
  margin-top: 0;
}

.Page .Content .Content__dedications p:last-child, .Page .Content .Content__testimonials p:last-child {
  margin-bottom: 0;
}

.Page .Content .Content__dedications p .highlight, .Page .Content .Content__testimonials p .highlight {
  font-weight: 500;
  font-style: italic;
}

.Page .Content .Content__dedications ul, .Page .Content .Content__testimonials ul {
  margin-bottom: 0;
}

.Page .Content .Content__dedications h2 {
  font-size: 24px;
}

.Page .Content .Content__dedications p {
  text-align: center;
}

@media screen and (min-width: 480px) {
  .Page .Content .Content__dedications, .Page .Content .Content__testimonials {
    padding: 50px;
  }
}

/* Content: Terms, Privacy */
.Page .Content .Content__terms, .Page .Content .Content__privacy {
  padding: 50px 15px;
  background-color: #C221F8;
  font-size: 16px;
  color: #fff;
  line-height: 34px;
}

.Page .Content .Content__terms h2, .Page .Content .Content__privacy h2 {
  font-size: 24px;
  color: #fff;
}

.Page .Content .Content__terms p, .Page .Content .Content__privacy p {
  margin: 25px 0;
}

.Page .Content .Content__terms p:first-child, .Page .Content .Content__privacy p:first-child {
  margin-top: 0;
}

.Page .Content .Content__terms p:last-child, .Page .Content .Content__privacy p:last-child {
  margin-bottom: 0;
}

.Page .Content .Content__terms p .highlight, .Page .Content .Content__privacy p .highlight {
  font-weight: 500;
  font-style: italic;
}

.Page .Content .Content__terms ul, .Page .Content .Content__privacy ul {
  margin-bottom: 0;
}

.Page .Content .Content__terms ul li, .Page .Content .Content__privacy ul li {
  color: #fff;
}

.Page .Content .Content__terms ul li a, .Page .Content .Content__privacy ul li a {
  color: blue;
}

.Page .Content .Content__dedications h2 {
  font-size: 24px;
}

.Page .Content .Content__dedications p {
  text-align: center;
}

@media screen and (min-width: 480px) {
  .Page .Content .Content__terms, .Page .Content .Content__privacy {
    padding: 50px;
  }
}

/* Content: Testimonials Overrides */
.Page .Content .Content__testimonials {
  padding: 50px 0 0;
}

.Page .Content .Content__testimonials #carousel-testimonials {
  padding-bottom: 100px;
}

.Page .Content .Content__testimonials #carousel-testimonials .carousel-indicators li {
  width: 12px;
  height: 12px;
  margin: 0;
  background: none;
  border: 1px solid #C221F8;
}

.Page .Content .Content__testimonials #carousel-testimonials .carousel-indicators li.active {
  background-color: #C221F8;
}

.Page .Content .Content__testimonials #carousel-testimonials .quote {
  text-align: center;
}

.Page .Content .Content__testimonials #carousel-testimonials .quote img {
  width: 100%;
  max-width: 700px;
}

.Page .Content .Content__testimonials #carousel-testimonials .quote p {
  padding: 0 150px;
  font-size: 18px;
  font-style: italic;
}

.Page .Content .Content__testimonials #carousel-testimonials .quote .author {
  font-size: 24px;
  font-style: normal;
}

.Page .Content .Content__testimonials #carousel-testimonials .carousel-control {
  background: none;
}

.Page .Content .Content__testimonials #carousel-testimonials .carousel-control .glyphicon {
  margin-top: -60px;
  color: #C221F8;
}

@media screen and (min-width: 480px) {
  .Page .Content .Content__testimonials {
    padding: 50px;
  }
}

/* Content: Lifeward Choices */
.Page__lifeward-choices .Content .Content__lifeward-choices {
  padding: 50px 15px;
  font-size: 16px;
  color: #000;
  line-height: 34px;
  background-color: #f5f5f5;
}

.Page__lifeward-choices .Content .Content__lifeward-choices p {
  margin: 25px 0;
}

.Page__lifeward-choices .Content .Content__lifeward-choices p:first-child {
  margin-top: 0;
}

.Page__lifeward-choices .Content .Content__lifeward-choices p:last-child {
  margin-bottom: 0;
}

.Page__lifeward-choices .Content .Content__lifeward-choices p .highlight {
  font-weight: 500;
  font-style: italic;
}

.Page__lifeward-choices .Content .Content__lifeward-choices p.free-gifts {
  text-align: center;
}

@media screen and (min-width: 480px) {
  .Page__lifeward-choices .Content .Content__lifeward-choices {
    padding: 50px;
  }
}

@media screen and (max-width: 767px) {
  .Page__lifeward-choices .Content .Content__testimonials #carousel-testimonials .quote p {
    padding: 0 50px;
  }
  .Page__lifeward-choices .Content .Content__testimonials #carousel-testimonials .quote .author {
    font-size: 18px;
  }
}

/* Footer */
.Footer {
  padding: 50px;
  border-bottom: 5px solid #18C3CA;
  background-color: #fff;
  font-size: 14px;
  color: #7f7f7f;
  text-align: center;
}

.Footer .Footer__bottom-nav {
  margin: 0;
  padding: 0;
}

.Footer .Footer__bottom-nav li {
  display: inline-block;
}

.Footer .Footer__bottom-nav li:before {
  content: '\00B7';
}

.Footer .Footer__bottom-nav li:first-child:before {
  content: none;
}

.Footer .Footer__bottom-nav li a {
  color: #000;
}

.Footer .Footer__bottom-nav li a:hover {
  text-decoration: underline;
}

.Footer .Footer__social-network {
  margin: 30px 0 0;
  padding: 0;
}

.Footer .Footer__social-network li {
  margin-left: 10px;
  display: inline-block;
}

.Footer .Footer__social-network li:first-child {
  margin-left: 0;
}

.Footer .Footer__social-network li a {
  max-width: 30px;
  display: block;
}

.Footer .Footer__social-network li a img {
  width: 100%;
}

.Footer .designed-hosted-by {
  margin: 25px 0 0;
}

.Footer .designed-hosted-by a {
  color: #7f7f7f;
}

.Footer .designed-hosted-by a:hover {
  text-decoration: underline;
}

@media screen and (min-width: 992px) {
  .Footer {
    text-align: left;
  }
  .Footer .designed-hosted-by {
    margin-top: 0;
    text-align: right;
  }
  .Footer .designed-hosted-by a {
    font-weight: 500;
  }
}
