/* Variables */
$screen-xs-min: 480px;
$screen-sm-min: 768px;
$screen-md-min: 992px;

$screen-xs-max: 767px;
$screen-sm-max: 991px;
$screen-md-max: 1199px;

/* Styles */
body {
    font-family: 'Anuphan', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #000;
}
strong {
    font-weight: 500;
}
.Page {
    background-image: url('/img/bg.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: #040205;
}
h1 {
    margin: 0;

    font-family: 'Noticia Text', serif;
    font-size: 30px;
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;
}
h2 {
    margin: 5px 0 0;

    font-family: 'Noticia Text', serif;
    font-size: 18px;
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;
    color: #C221F8;
}
h3 {
    margin: 0;

    font-family: 'Noticia Text', serif;
    font-size: 30px;
    font-weight: 300;
    text-align: center;
}
h4 {
    margin: 5px 0 0;

    font-family: 'Noticia Text', serif;
    font-size: 24px;
    font-weight: 300;
    font-style: italic;
    text-align: center;
    color: #330475;
}
.underline {
    width: 75px;
    height: 2px;

    margin: 25px auto 0;

    background-color: #18C3CA;
}
.swal-bio {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;

    h2 {
        color: #000;
    }
    p {
        margin-top: 25px;

        font-size: 16px;
        color: #000;
        text-align: left;
        line-height: 34px;

        &:last-child {
            margin-bottom: 25px;
        }
    }
}
.alert-success {
    background-color: #C221F8;
    color: #fff;
    border-color: #ac1ddc;
}

/* Layout */
.wrapper {
    padding: 75px 0;
}

/* Header */
.Header {
    padding-top: 40px;

    #logo {
        img {
            width: 100%;
        }
    }
    .Header__top-navigation {
        margin-top: 75px;

        //background: #000e71; /* For browsers that do not support gradients */
        //background: -webkit-linear-gradient(#000e71, #0a0d3d); /* For Safari 5.1 to 6.0 */
        //background: -o-linear-gradient(#000e71, #0a0d3d); /* For Opera 11.1 to 12.0 */
        //background: -moz-linear-gradient(#000e71, #0a0d3d); /* For Firefox 3.6 to 15 */
        //background: linear-gradient(#000e71, #0a0d3d); /* Standard syntax */
        //background: #2176FF;
        background: #330475;

        ul {
            margin: 0;
            padding: 0;

            list-style: none;

            li {
                border-bottom: 1px solid #040205;

                &:last-child {
                    border-bottom: none;

                    a {
                        margin-bottom: 0;

                        border-bottom: none;
                    }
                }
                &.active {
                    a {
                        //color: #18C3CA;
                        color: #ffff93;
                    }
                }
                &.free-sessions {
                    //background: #e73e30; /* For browsers that do not support gradients */
                    //background: -webkit-linear-gradient(#e73e30, #c72026); /* For Safari 5.1 to 6.0 */
                    //background: -o-linear-gradient(#e73e30, #c72026); /* For Opera 11.1 to 12.0 */
                    //background: -moz-linear-gradient(#e73e30, #c72026); /* For Firefox 3.6 to 15 */
                    //background: linear-gradient(#e73e30, #c72026); /* Standard syntax */
                    background: #18C3CA;
                }
                a {
                    width: 100%;
                    height: 50px;

                    display: block;
                    margin-bottom: 1px;
                    padding-top: 8px;

                    color: #fff;
                    text-align: center;

                    &:hover, &:focus {
                        text-decoration: none;
                    }
                    &:hover {
                        //color: #18C3CA;
                        color: #ffff93;
                    }
                }
            }
        }
        ul.nav-trigger {
            li {
                border-bottom: none;

                a {
                    padding-top: 10px;

                    font-size: 18px;
                }
            }
        }
        ul.nav-items {
            display: none;

            li {
                a {
                    padding-top: 10px;

                    font-size: 18px;
                }
            }
        }
        @media screen and (min-width: $screen-sm-min) {
            height: 50px;

            ul.nav-trigger {
                display: none;
            }
            ul.nav-items {
                margin: 0;
                padding: 0;

                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                list-style: none;

                li {
                    display: flex;
                    flex-grow: 1;
                    align-items: flex-start;
                    border-left: 1px solid #040205;
                    border-bottom: none;

                    &:first-child {
                        border-left: none;
                    }
                    &:last-child {
                        border-right: none;
                    }
                    &.active {
                        a {
                            //color: #18C3CA;
                            color: #ffff93;
                        }
                    }
                    a {
                        width: 100%;
                        height: 49px;

                        display: block;
                        padding-top: 12px;

                        font-family: 'Anuphan', sans-serif;
                        font-weight: 400;
                        font-size: 18px;
                        color: #fff;
                        text-align: center;
                        border-bottom: none;

                        &:hover, &:focus {
                            text-decoration: none;
                        }
                        &:hover {
                            //color: #18C3CA;
                            color: #ffff93;
                        }
                    }
                }
            }
        }
    }
    @media screen and (min-width: $screen-xs-min) {
        #logo {
            max-width: 500px;

            display: block;
            margin: -20px auto 0;
        }
    }
}

/* Banner */
.Banner {
    height: 360px;

    &.Banner__welcome {
        background: url('/img/banner/welcome.jpg?v=2') no-repeat center;
        background-size: cover;
    }
    &.Banner__about-us {
        background: url('/img/banner/about-us.jpg') no-repeat center;
        background-size: cover;
    }
    &.Banner__free-sessions {
        background: url('/img/banner/free-sessions.jpg') no-repeat center;
        background-size: cover;
    }
    &.Banner__love-made-easy {
        background: url('/img/banner/love-made-easy.jpg') no-repeat center;
        background-size: cover;
    }
    &.Banner__teen-mentorship {
        background: url('/img/banner/teen-mentorship.jpg') no-repeat center;
        background-size: cover;
    }
    &.Banner__executives {
        background: url('/img/banner/executives.jpg') no-repeat center;
        background-size: cover;
    }
    &.Banner__social-master {
        background: url('/img/banner/social-master.jpg?v=3') no-repeat center right;
        background-size: cover;
    }
    &.Banner__stress-free-and-happy {
        background: url('/img/banner/stress-free-and-happy.jpg?v=3') no-repeat center;
        background-size: cover;
    }
    &.Banner__student-success {
        background: url('/img/banner/student-success.jpg') no-repeat center;
        background-size: cover;
    }
    &.Banner__crg {
        background: url('/img/banner/crg.jpg') no-repeat center;
        background-size: cover;
    }
    &.Banner__crg-change {
        background: url('/img/banner/crg-change.jpg') no-repeat center;
        background-size: cover;
    }
    &.Banner__crg-renewal {
        background: url('/img/banner/crg-renewal.jpg') no-repeat center;
        background-size: cover;
    }
    &.Banner__crg-growth {
        background: url('/img/banner/crg-growth.jpg') no-repeat center;
        background-size: cover;
    }
    &.Banner__1-1-coaching {
        background: url('/img/banner/1-1-coaching.jpg') no-repeat center;
        background-size: cover;
    }
    &.Banner__contact-us {
        background: url('/img/banner/contact-us.jpg') no-repeat center;
        background-size: cover;
    }
    &.Banner__lifeward-choices {
        background: url('/img/banner/contact-us.jpg') no-repeat center;
        background-size: cover;
    }
    .slogan {
        width: 275px;
        position: absolute;

        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        padding: 7px 0;

        font-size: 18px;
        font-weight: 500;
        color: #fff;
        text-transform: uppercase;
        text-align: center;

        background: #18C3CA;
        //background: -webkit-linear-gradient(#e73e30, #c72026); /* For Safari 5.1 to 6.0 */
        //background: -o-linear-gradient(#e73e30, #c72026); /* For Opera 11.1 to 12.0 */
        //background: -moz-linear-gradient(#e73e30, #c72026); /* For Firefox 3.6 to 15 */
        //background: linear-gradient(#e73e30, #c72026); /* Standard syntax */
    }
}

/* Content */
.Page {
    .Content {
        background-color: #fff;

        .Content__title {
            padding: 50px;
        }
    }
    // .mindset-makeover {
    //     .banner {
    //         position: fixed;
    //         width: 100%;
    //         padding: 10px 0;
    //         z-index: 100;
            
    //         font-size: 16px;
    //         color: #fff;
    //         background-color: #18C3CA;
    //         text-align: center;
    //     }
    //     #mindset-makeover {
    //         text-align: center;

    //         h3 {
    //             font-size: 22px;
    //         }
    //         p {
    //             margin-top: 25px;

    //             font-size: 16px;
    //         }
    //         .learn-more {
    //             display: inline-block;
    //             padding: 9px 11px;

    //             font-size: 14px;
    //             font-weight: 500;

    //             color: #fff;
    //             text-transform: uppercase;
    //             background-color: #18C3CA;

    //             &:hover, &:focus {
    //                 text-decoration: none;
    //             }
    //         }
    //     }
    // }
    .mindset-makeover {
        .banner {
            height: 50px;
            position: fixed;
            z-index: 100;
            padding: 12px 20px;

            top: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            -webkit-transform-origin: 0 50%;
               -moz-transform-origin: 0 50%;
                -ms-transform-origin: 0 50%;
                 -o-transform-origin: 0 50%;
                    transform-origin: 0 50%;
            -webkit-transform: rotate(-90deg) translate(-50%, 50%);
               -moz-transform: rotate(-90deg) translate(-50%, 50%);
                -ms-transform: rotate(-90deg) translate(-50%, 50%);
                 -o-transform: rotate(-90deg) translate(-50%, 50%);
                    transform: rotate(-90deg) translate(-50%, 50%);

            
            font-size: 16px;
            color: #fff;
            background-color: #18C3CA;
            text-align: center;
            
            .highlight {
                font-size: 18px;
                font-weight: 600;
                color: rgb(200, 0, 0);
            }
        }
        #mindset-makeover {
            text-align: center;

            h3 {
                font-size: 22px;
            }
            p {
                margin-top: 25px;

                font-size: 16px;
            }
            .learn-more {
                display: inline-block;
                padding: 9px 11px;

                font-size: 14px;
                font-weight: 500;

                color: #fff;
                text-transform: uppercase;
                background-color: #18C3CA;

                &:hover, &:focus {
                    text-decoration: none;
                }
            }
        }
    }
}

/* Content: Welcome */
.Page__welcome {
    .Content {
        .Content__crg {
            padding: 50px 15px;

            text-align: center;

            .crg {
                margin: 50px 0;
            }
            p {
                margin: 50px 0 15px;

                font-size: 16px;
                color: #000;
                line-height: 34px;
            }
            .learn-more {
                display: inline-block;
                padding: 9px 11px;

                font-size: 14px;
                font-weight: 500;

                color: #fff;
                text-transform: uppercase;
                background-color: #18C3CA;

                &:hover, &:focus {
                    text-decoration: none;
                }
            }
        }
        .Content__title {
            background-color: #C221F8;

            h1, h2 {
                color: #fff;
            }
            .underline {
                background-color: #fff;
            }
        }
        .Content__most-popular {
            padding: 0 50px 50px;

            background-color: #C221F8;

            .programs-list {
                &:first-child {
                    div:first-child {
                        .program {
                            margin-top: 0;
                        }
                    }
                }
                .program {
                    max-width: 415px;
                    height: 200px;

                    margin: 50px auto 0;
                    position: relative;
                    display: block;

                    border: 3px solid #fff;

                    &.love-made-easy {
                        background: url('/img/programs/bg-love-made-easy.jpg') no-repeat center;
                        background-size: cover;
                    }
                    &.teen-mentorship {
                        background: url('/img/programs/bg-teen-mentorship.jpg') no-repeat center;
                        background-size: cover;
                    }
                    &.executives {
                        background: url('/img/programs/bg-executives.jpg') no-repeat center;
                        background-size: cover;
                    }
                    &.social-master {
                        background: url('/img/programs/bg-social-master.jpg?v=3') no-repeat center;
                        background-size: cover;
                    }
                    &.stress-free-and-happy {
                        background: url('/img/programs/bg-stress-free-and-happy.jpg?v=2') no-repeat center;
                        background-size: cover;
                    }
                    &.student-success {
                        background: url('/img/programs/bg-student-success.jpg') no-repeat center;
                        background-size: cover;
                    }
                    .title {
                        max-width: 200px;
                        position: absolute;

                        left: 0;
                        right: 0;
                        bottom: 0;
                        margin: 0 auto;
                        padding: 5px 0 2px;

                        font-size: 16px;
                        font-weight: 500;
                        color: #330475;
                        text-transform: uppercase;
                        text-align: center;

                        background: #fff;
                        //background: -webkit-linear-gradient(#e73e30, #c72026); /* For Safari 5.1 to 6.0 */
                        //background: -o-linear-gradient(#e73e30, #c72026); /* For Opera 11.1 to 12.0 */
                        //background: -moz-linear-gradient(#e73e30, #c72026); /* For Firefox 3.6 to 15 */
                        //background: linear-gradient(#e73e30, #c72026); /* Standard syntax */
                    }
                }
            }
            @media screen and (min-width: $screen-md-min) {
                .programs-list:first-child {
                    div:last-child {
                        .program {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
        @media screen and (min-width: $screen-xs-min) {
            .Content__crg {
                padding: 50px;
            }
            .Content__welcome {
                .sarah-aguinaldo {
                    width: 290px;
                    height: 200px;
                }
            }
        }
    }
}

/* Content: About Us */
.Page__about-us {
    .Content {
        .Content__about-us {
            padding: 50px 15px;

            font-size: 16px;
            color: #000;
            line-height: 34px;

            background-color: #C221F8;

            p {
                margin: 25px 0;

                &:first-child {
                    margin-top: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                .highlight {
                    font-weight: 500;
                    font-style: italic;
                }
            }
            ul {
                li {
                    color: #fff;

                    a {
                        color: #fff;
                        text-decoration: none;
                        font-weight: 600;

                        &:hover {
                            border-bottom: 1px solid #fff;
                        }
                    }
                }
            }
        }
        @media screen and (min-width: $screen-xs-min) {
            .Content__about-us {
                padding: 50px;
            }
        }
    }
}

/* Content: Free Sessions */
.Page__free-sessions {
    .Content {
        .Content__title {
            text-align: center;

            h2 {
                margin-top: 25px;

                .highlight {
                    color: rgb(11, 11, 255);
                }
                a {
                    color: #C221F8;
                    text-decoration: none;

                    &:hover {
                        border-bottom: 1px solid rgb(11, 11, 255);
                    }
                }
            }
            .underline {
                margin: 25px auto;
            }
        }
        .Content__free-sessions {
            padding: 50px 15px;

            font-size: 16px;
            color: #000;
            line-height: 34px;

            background-color: #f5f5f5;

            .jump-girl {
                text-align: center;

                img {
                    width: 100%;
                    max-width: 350px;
                }
            }
            h2 {
                margin: 50px auto 30px;

                font-size: 24px;

                .highlight {
                    color: rgb(11, 11, 255);
                }
            }
            ul {
                margin-left: 50px;

                font-size: 18px;
            }
            p {
                margin: 25px 0;

                &:first-child {
                    margin-top: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                .highlight {
                    font-weight: 500;
                    font-style: italic;
                }
            }
            .form {
                margin-top: 50px;

                text-align: center;

                p {
                    margin-bottom: 0;

                    font-size: 18px;

                    &:first-child {
                        color: #337ab7;
                    }
                    .highlight {
                        font-style: normal;
                    }
                }
                > ul {
                    color: #337ab7;
                    text-align: left;
                }
                .form-free-session {
                    margin-top: 50px;

                    text-align: left;

                    ul {
                        margin-left: 25px;

                        font-size: 16px;
                    }
                    label {
                        font-weight: 500;
                    }
                    input[type="text"],
                    input[type="email"],
                    input[type="tel"] {
                        height: auto;

                        padding: 12px 16px;

                        font-size: 16px;
                        border-radius: 0;
                    }
                    button {
                        font-size: 16px;
                        border-radius: 0;
                    }
                    .btn-submit {
                        color: #fff;
                        background-color: #C221F8;
                        border-color: #ac1ddc;
                    }
                }
            }
        }
        @media screen and (min-width: $screen-xs-min) {
            .Content__free-sessions {
                padding: 50px;
            }
        }
    }
}

/* Content: 1:1 Coaching */
.Page__1-1-coaching {
    .Content {
        .Content__title {
            text-align: center;

            h2 {
                margin-top: 25px;
                font-size: 24px;

                .highlight {
                    color: rgb(11, 11, 255);
                }
                a {
                    color: #C221F8;
                    text-decoration: none;

                    &:hover {
                        border-bottom: 1px solid rgb(11, 11, 255);
                    }
                }
            }
            .underline {
                margin: 25px auto;
            }
        }
        .Content__1-1-coaching {
            padding: 50px 15px;

            font-size: 16px;
            color: #000;
            line-height: 34px;

            background-color: #f5f5f5;

            h2 {
                margin: 50px auto 30px;

                font-size: 24px;

                .highlight {
                    color: rgb(11, 11, 255);
                }
            }
            p {
                margin: 25px 0;

                font-size: 18px;
                text-align: center;

                &:first-child {
                    margin-top: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                .highlight {
                    font-weight: 500;
                    font-style: italic;
                }
            }
            .form {
                margin-top: 50px;

                text-align: center;

                p {
                    margin-bottom: 0;

                    font-size: 18px;

                    &:first-child {
                        color: #337ab7;
                    }
                    .highlight {
                        font-style: normal;
                    }
                }
                > ul {
                    color: #337ab7;
                    text-align: left;
                }
                .form-free-session {
                    margin-top: 50px;

                    text-align: left;

                    ul {
                        margin-left: 25px;

                        font-size: 16px;
                    }
                    label {
                        font-weight: 500;
                    }
                    input[type="text"],
                    input[type="email"],
                    input[type="tel"] {
                        height: auto;

                        padding: 12px 16px;

                        font-size: 16px;
                        border-radius: 0;
                    }
                    button {
                        font-size: 16px;
                        border-radius: 0;
                    }
                    .btn-submit {
                        color: #fff;
                        background-color: #C221F8;
                        border-color: #ac1ddc;
                    }
                }
            }
        }
        @media screen and (min-width: $screen-xs-min) {
            .Content__free-sessions {
                padding: 50px;
            }
        }
    }
}

/* Content: Sign Waiver */
.Page__sign-waiver {
    .mindset-makeover {
        display: none;
    }
    .Content {
        .Content__title {
            text-align: center;

            h2 {
                margin-top: 25px;

                .highlight {
                    color: rgb(11, 11, 255);
                }
                a {
                    color: #C221F8;
                    text-decoration: none;

                    &:hover {
                        border-bottom: 1px solid rgb(11, 11, 255);
                    }
                }
            }
            .underline {
                margin: 25px auto;
            }
        }
        .Content__sign-waiver {
            padding: 50px 15px;

            font-size: 16px;
            color: #000;
            line-height: 34px;

            background-color: #f5f5f5;

            .logo {
                margin-bottom: 25px;

                text-align: center;
            }
            p {
                margin: 25px 0;

                &:first-child {
                    margin-top: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                .highlight {
                    font-weight: 500;
                    font-style: italic;
                }
            }
            .form {
                margin-top: 50px;

                text-align: center;

                p {
                    margin-bottom: 0;

                    font-size: 18px;

                    &:first-child {
                        color: #337ab7;
                    }
                    .highlight {
                        font-style: normal;
                    }
                }
                > ul {
                    color: #337ab7;
                    text-align: left;
                }
                .form-sign-waiver {
                    text-align: left;

                    ul {
                        margin-left: 25px;

                        font-size: 16px;
                    }
                    label {
                        font-weight: 500;
                    }
                    input[type="text"],
                    input[type="email"],
                    input[type="tel"] {
                        height: auto;

                        padding: 12px 16px;

                        font-size: 16px;
                        border-radius: 0;
                    }
                    .form-check {
                        label {
                            margin-left: 10px;

                            display: inline;
                        }
                    }
                    button {
                        font-size: 16px;
                        border-radius: 0;
                    }
                    .btn-submit {
                        color: #fff;
                        background-color: #C221F8;
                        border-color: #ac1ddc;
                    }
                }
            }
        }
        @media screen and (min-width: $screen-xs-min) {
            .Content__sign-waiver {
                padding: 50px;
            }
        }
    }
    @media screen and (min-width: $screen-sm-min) {
        .mindset-makeover {
            display: block;
        }
    }
}

/* Content: CRG */
[class*="Page__crg"] {
    .Content {
        .Content__crg {
            padding: 50px 15px;

            font-size: 16px;
            text-align: center;
            background-color: #f5f5f5;

            h2 {
                font-size: 24px;
            }
            p {
                margin: 25px 0;

                color: #000;
                line-height: 34px;
                text-align: left;

                &:first-child {
                    margin-top: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                .highlight {
                    font-weight: 500;
                    font-style: italic;
                }
            }
            ul {
                margin-bottom: 0;

                text-align: left;
            }
            .phase {
                max-width: 200px;

                position: relative;
                display: block;
                margin: 0 auto;
                padding: 10px 15px;

                background-color: #C221F8;
                text-decoration: none;

                &:after{
                    content: '';
                    position: absolute;
                    top: 50%;
                    right:-30px;
                    margin-top: -15px;
                    z-index: 1;
                    border: solid 15px transparent;
                    border-left-color: #C221F8;
                }
                p {
                    font-size: 24px;
                    color: #fff;
                    text-transform: uppercase;
                    line-height: 28px;

                    .subtitle {
                        display: block;

                        font-size: 18px;
                        font-style: italic;
                        color: #fff;
                    }
                }
            }
            .intro {
                p {
                    font-weight: 500;
                    text-align: center;
                }
            }
        }
        @media screen and (max-width: $screen-md-min) {
            .Content__crg {
                .phase {
                    margin-top: 15px;
                }
            }
        }
        @media screen and (min-width: $screen-xs-min) {
            .Content__crg {
                padding: 50px;
            }
        }
    }
}

/* Content: Most Popular Programs */
.Page {
    .Content {
        .Content__program {
            padding: 50px 15px;

            background-color: #C221F8;

            font-size: 16px;
            color: #000;
            line-height: 34px;

            p {
                margin: 25px 0;
                color: #fff;

                &:first-child {
                    margin-top: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                .highlight {
                    font-weight: 500;
                    font-style: italic;
                }
                a {
                    color: #fff;
                    text-decoration: none;
                    font-weight: 600;

                    &:hover {
                        border-bottom: 1px solid #fff;
                    }
                }
            }
            ul {
                margin-bottom: 0;

                li {
                    color: #fff;

                    a {
                        color: #fff;
                        text-decoration: none;
                        font-weight: 600;

                        &:hover {
                            border-bottom: 1px solid #fff;
                        }
                    }
                }
            }
        }
        @media screen and (min-width: $screen-xs-min) {
            .Content__program {
                padding: 50px;
            }
        }
    }
}

/* Content: Contact Us */
.Content {
    .Content__title {
        padding: 50px;
    }
    .Content__contact-us {
        padding: 50px 15px;

        background-color: #C221F8;

        .info {
            &:first-child {
                h2 {
                    margin-top: 0;
                }
            }
            h2 {
                margin-top: 50px;

                color: #fff;
                text-align: left;
            }
            p {
                margin-top: 5px;

                font-size: 24px;
                color: #fff;
                word-break: break-word;

                a {
                    color: #fff;
                    text-decoration: none;

                    &:hover {
                        border-bottom: 1px solid #fff;
                    }
                }
            }
        }
        p {
            margin: 25px 0;

            font-size: 16px;
            color: #fff;
            line-height: 34px;

            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    @media screen and (min-width: $screen-xs-min) {
        .Content__contact-us {
            padding: 50px;
        }
    }
}

/* Content: Founder */
.Page__founder {
    .Content {
        .Content__founder {
            padding: 50px 15px;

            background-color: #f5f5f5;

            font-size: 16px;
            color: #000;
            line-height: 34px;
            text-align: center;

            p {
                margin: 25px 0;

                text-align: left;

                &:last-child {
                    margin-bottom: 0;
                }
                &.free-gifts {
                    text-align: center;

                    a {
                        color: red;
                        font-weight: bold;
                    }
                }
                .highlight {
                    font-weight: 500;
                    font-style: italic;
                }
            }
            .bio {
                p {
                    text-indent: 40px;
                }
            }
            #jim-and-jim {
                text-align: left;

                .modal-content {
                    border-radius: 0;

                    img {
                        width: 100%;
                    }
                    .jim-britt, .jim-lutes {
                        font-size: 16px;
                        line-height: 26px;
                    }
                    .jim-lutes {
                        margin-top: 50px;
                    }
                    .btn {
                        border-radius: 0;
                    }
                }
            }
            #sarah-message {
                .modal-content {
                    border-radius: 0;

                    .btn {
                        border-radius: 0;
                    }
                    .highlight {
                        font-style: normal;
                        color: #C221F8;
                    }
                }
            }
        }
        @media screen and (min-width: $screen-xs-min) {
            .Content__founder {
                padding: 50px;
            }
        }
    }
}

/* Content: Dedications, Testimonials */
.Page {
    .Content {
        .Content__dedications, .Content__testimonials {
            padding: 50px 15px;

            background-color: #f5f5f5;

            font-size: 16px;
            color: #000;
            line-height: 34px;

            h2 {
                font-size: 24px;
            }
            p {
                margin: 25px 0;

                &:first-child {
                    margin-top: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                .highlight {
                    font-weight: 500;
                    font-style: italic;
                }
            }
            ul {
                margin-bottom: 0;
            }
        }
        .Content__dedications {
            h2 {
                font-size: 24px;
            }
            p {
                text-align: center;
            }
        }
        @media screen and (min-width: $screen-xs-min) {
            .Content__dedications, .Content__testimonials {
                padding: 50px;
            }
        }
    }
}

/* Content: Terms, Privacy */
.Page {
    .Content {
        .Content__terms, .Content__privacy {
            padding: 50px 15px;

            background-color: #C221F8;

            font-size: 16px;
            color: #fff;
            line-height: 34px;

            h2 {
                font-size: 24px;
                color: #fff;
            }
            p {
                margin: 25px 0;

                &:first-child {
                    margin-top: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                .highlight {
                    font-weight: 500;
                    font-style: italic;
                }
            }
            ul {
                margin-bottom: 0;

                li {
                    color: #fff;

                    a {
                        color: blue;
                    }
                }
            }
        }
        .Content__dedications {
            h2 {
                font-size: 24px;
            }
            p {
                text-align: center;
            }
        }
        @media screen and (min-width: $screen-xs-min) {
            .Content__terms, .Content__privacy {
                padding: 50px;
            }
        }
    }
}

/* Content: Testimonials Overrides */
.Page {
    .Content {
        .Content__testimonials {
            padding: 50px 0 0;

            #carousel-testimonials {
                padding-bottom: 100px;

                .carousel-indicators {
                    li {
                        width: 12px;
                        height: 12px;

                        margin: 0;

                        background: none;
                        border: 1px solid #C221F8;

                        &.active {
                            background-color: #C221F8;
                        }
                    }
                }
                .quote {
                    text-align: center;

                    img {
                        width: 100%;
                        max-width: 700px;
                    }
                    p {
                        padding: 0 150px;

                        font-size: 18px;
                        font-style: italic;
                    }
                    .author {
                        font-size: 24px;
                        font-style: normal;
                    }
                }
                .carousel-control {
                    background: none;

                    .glyphicon {
                        margin-top: -60px;

                        color: #C221F8;
                    }
                }
            }
        }
        @media screen and (min-width: $screen-xs-min) {
            .Content__testimonials {
                padding: 50px;
            }
        }
    }
}
/* Content: Lifeward Choices */
.Page__lifeward-choices {
    .Content {
        .Content__lifeward-choices {
            padding: 50px 15px;

            font-size: 16px;
            color: #000;
            line-height: 34px;

            background-color: #f5f5f5;

            p {
                margin: 25px 0;

                &:first-child {
                    margin-top: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                .highlight {
                    font-weight: 500;
                    font-style: italic;
                }
                &.free-gifts {
                    text-align: center;
                }
                
            }
        }
        @media screen and (min-width: $screen-xs-min) {
            .Content__lifeward-choices {
                padding: 50px;
            }
        }
    }
    @media screen and (max-width: $screen-xs-max) {
        .Content {
            .Content__testimonials {
                #carousel-testimonials {
                    .quote {
                        p {
                            padding: 0 50px;
                        }
                        .author {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
}

/* Footer */
.Footer {
    padding: 50px;

    border-bottom: 5px solid #18C3CA;
    background-color: #fff;

    font-size: 14px;
    color: #7f7f7f;
    text-align: center;

    .Footer__bottom-nav {
        margin: 0;
        padding: 0;

        li {
            display: inline-block;

            &:before {
                content: '\00B7';
            }
            &:first-child:before {
                content: none;
            }
            a {
                color: #000;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    .Footer__social-network {
        margin: 30px 0 0;
        padding: 0;

        li {
            margin-left: 10px;
            display: inline-block;

            &:first-child {
                margin-left: 0;
            }
            a {
                max-width: 30px;
                display: block;

                img {
                    width: 100%;
                }
            }
        }
    }
    .designed-hosted-by {
        margin: 25px 0 0;

        a {
            color: #7f7f7f;

            &:hover {
                text-decoration: underline;
            }
        }
    }
    @media screen and (min-width: $screen-md-min) {
        text-align: left;

        .designed-hosted-by {
            margin-top: 0;

            text-align: right;

            a {
                font-weight: 500;
            }
        }
    }
}
